import React, { useState, useEffect } from 'react';
import useLocale from '../../hooks/useLocale';
import styles from './StorageError.scss';
import { detect } from 'detect-browser';

const StorageError = (): JSX.Element => {
  const [browserDocsLink, setBrowserDocsLink] = useState('');
  const { getLocale } = useLocale();
  const browser = detect();

  useEffect(() => {
    if (browser) {
      switch (browser.name) {
        case 'chrome':
          console.log('chrome');
          setBrowserDocsLink(getLocale('StorageErrorLinkChrome'));
          break;
        case 'safari':
          setBrowserDocsLink(getLocale('StorageErrorLinkSafari'));
          break;
        default:
          setBrowserDocsLink('');
          break;
      }
    }
  }, []);

  return (
    <div id={styles.errorMessage}>
      <h2>{getLocale('StorageErrorTitle')}</h2>
      <p>{getLocale('StorageErrorMessage')}</p>
      { browserDocsLink ? 
        <a href={browserDocsLink} target='_blank' rel='noreferrer'>{getLocale('StorageErrorLinkText')}</a>
        :
        <p>{getLocale('StorageErrorLinkFallbackMessage')}</p>
      }
    </div>
  );
};

export default StorageError;