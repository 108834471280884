import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Contract, PostContract, ContractType, PostViewedContract } from '../../models/contract';
import { ApiService } from '../../services';
import { ContractSignHookState, SignPageState, initialContract } from './ContractSign.types';
import { AppContext } from '../../context/appContext';
import { Routes } from '../../models/AppTypes';
import useExceptionFlow from '../../hooks/useExceptionFlow';
import PostService from '../../services/PostService';
import { useAnalytics } from '../../analytics';

/**
 * Purpose:
 * The use Contract hook is used for signing a contract. The contract is received from the fetchContract API
 * The payload may include signed and/or unsigned contracts in payload.
 *  Each contract is shown to the user, if there are more contracts to be signed then the next contract will be shown.
 * once all the contract are signed then the user is routed to the contract complete page
 */

const useContract = (): {
  state: ContractSignHookState;
  onSignContract: () => void;
  onSetClickwrapParameters: (parameter: string, value: object, context: any) => void;
  onSendEmailLink: (data?: { email: string }) => void;
  onCloseSaveLaterModal: () => void;
  handleManualSaveLaterSubmit: React.FormEventHandler;
  onContractViewed: (downloadUrl: string) => void;
} => {
  const analytics = useAnalytics();
  const appContext = useContext(AppContext);
  const [state, setState] = useState<ContractSignHookState>(initialContract);
  const ApiRef = useRef<ApiService>(new ApiService());
  const PostApiRef = useRef<PostService>(new PostService());
  const navigate = useNavigate();
  const { exceptionFlow } = useExceptionFlow();
  const [ipFetch, setIpFetch] = useState<Promise<string>>(null);

  /**
     * Purpose: This function updates the interface with the contract information
     * The contract to be signed is assigned to the state variables which are updated
     */

  const updateContract = (payload: Contract): void => {
    setState((pre) => ({
      ...pre,
      footerAlerts: payload.unsignedContract.template.content.footer ?? [],
      contract: {
        id: payload.unsignedContract.template.chainStepId,
        provider: payload.unsignedContract.template.provider,
        content: payload.unsignedContract.template.content.body,
        ironcladProps: payload.unsignedContract.template.ironcladProps,
        htmlContractProps: payload.unsignedContract.template.htmlProviderProps,
        renderData: payload.unsignedContract.template.renderData,
      },
      subTitle: payload.unsignedContract.template.content.header,
      title: payload.unsignedContract.template.content.title,
      documentType: payload.unsignedContract.template.documentType as ContractType,
      mode: SignPageState.Loaded,
      currentContract: payload,
    }));
  };

  const getPostContract = async (): Promise<PostContract> => {
    const signedContract: PostContract = {
      applicationId: state.currentContract.applicationId,
      chainStepId: state.currentContract.unsignedContract.template.chainStepId,
      partnerCode: appContext.partnerCode,
      ipAddress: ipFetch ? await ipFetch : '',
      isPrimary: appContext.isPrimary,
      isClickwrap: !!state.contract.ironcladProps || !!state.contract.provider,
      clickwrapMetadata: state.clickwrapMetadata,
      templateId: state.currentContract.unsignedContract.template.templateId,
      documentType: state.currentContract.unsignedContract.template.documentType || '',
      date: state.currentContract.unsignedContract.date,
      locale: null,
      signedCopy: {
        body: state.currentContract.unsignedContract.template.content.body,
        footer: state.currentContract.unsignedContract.template.content.footer,
        header: state.currentContract.unsignedContract.template.content.header,
        title: state.currentContract.unsignedContract.template.content.title,
      },
      name: state.currentContract.unsignedContract.name,
    };
    return signedContract;
  };
  const setErrorState = () => {
    setState((pre) => ({ ...pre, mode: SignPageState.Error }));
  };

  /**
     * Purpose: Load the contract from API, If the status is Completed
     * it change the variable mode to SignPageState.SignComplete then it route it to Contract Complete Page
     *
     */

  const loadContract = async () => {
    const source = appContext.isInIframe ? 'in_journey' : 'email';
    const mark = performance.now();
    try {
      analytics?.trackEvent('contract-load', {
        source,
      });
      const response = await ApiRef.current.fetchContract();
      setIpFetch(ApiRef.current.fetchIpAddress());

      if (response === null) {
        analytics?.trackApi('GET contract', performance.now() - mark, false);
        analytics?.trackError('Contract fetch returned null', { action: 'contract-load-failure', source });
        setErrorState();
        return;
      }

      analytics?.trackApi('GET contract', performance.now() - mark, true);
      if (response.status === 'Completed') {
        setState((pre) => ({
          ...pre,
          mode: SignPageState.SignComplete,
          currentContract: response,
        }));
      } else {
        updateContract(response);
      }
      appContext.setApplicationContract({
        applicationId: response.applicationId,
        applicantId: response.applicantId,
      });
      analytics?.trackEvent('contract-load-success', {
        source,
      });
    } catch (e) {
      analytics?.trackApi('GET contract', performance.now() - mark, false);
      analytics?.trackError(e, { action: 'contract-load-failure', source });
      exceptionFlow(e);
      setErrorState();
    }
  };

  const postContractViewed = (downloadUrl: string) => {
    if (state.currentContract?.unsignedContract?.template == null) {
      console.warn('Failed to save viewed event: no unsigned contract');
      analytics?.trackError('Failed to save viewed event: no unsigned contract', { action: 'postContractViewed' });
      return;
    }

    const payload: PostViewedContract = {
      chainStepId: state.currentContract.unsignedContract.template.chainStepId,
      newStatus: 'Viewed',
      clickwrapMetadata: {
        downloadUrl: downloadUrl,
        payload: {
          documentType: state.currentContract.unsignedContract.template.documentType,
        },
      },
      source: appContext.isInIframe ? 'in_journey' : 'email'
    };
    
    ApiRef.current.postContractView(payload)
      .catch(e => {
        console.warn('Failed to save viewed event', e);
        analytics?.trackError(e, { action: 'postContractViewed' });
      });
  };

  /**
     * Purpose: This function is called once when the user clicks on Sign Contract.
     * It Post the value to Api postContract
     * and then checks the status of contractSigned state variable.
     * If it is completed then the state variable: mode: SignPageState.SignComplete, is updated.
     */

  const postSignContract = async () => {
    const source = appContext.isInIframe ? 'in_journey' : 'email';
    const mark = performance.now();
    try {
      analytics?.trackEvent('sign-contract', {
        source,
      });
      setState((pre) => ({ ...pre, mode: SignPageState.Loading }));
      const postPayload = await getPostContract();
      const response = await ApiRef.current.postContract(postPayload);
      analytics?.trackApi('POST contract', performance.now() - mark, true);
      if (response.status === 'Completed') {
        setState((pre) => ({
          ...pre,
          mode: SignPageState.SignComplete,
          currentContract: response,
        }));
      } else {
        updateContract(response);
      }
      analytics?.trackEvent('sign-contract-success', {
        source,
      });
    } catch (e) {
      analytics?.trackApi('POST contract', performance.now() - mark, false);
      analytics?.trackError(e, { action: 'sign-contract-failure', source });
      exceptionFlow(e);
      setErrorState();
    }
  };

  const signContract = () => {
    const payload = {
      trackingEventName: 'Button Click',
      trackingLabel: 'sign-contract',
    };
    const data = JSON.stringify(payload);
    PostApiRef.current.postToParent(data);
    postSignContract();
  };

  const setStateValues = (parameter: string, value: object, context: any) => {
    if (parameter === 'download_url') {
      setState(pre => ({ ...pre, clickwrapMetadata: { ...pre.clickwrapMetadata, downloadUrl: value.toString() } }));
    }
    if (parameter === 'agreed') {
      setState(pre => ({ ...pre, clickwrapMetadata: { ...pre.clickwrapMetadata, payload: context.toJSON() } }));
    }
  };

  /**
     * Purpose: This function calls the api postContractLink which sends the magic link email.
     * Contract pulls these fields from the SF applicationId.
     * Payload:
     * email: '',
     * locale: '',
     */

  const sendEmailLink = async ({ email } = { email: '' }) => {
    const payload = {
      trackingEventName: 'Button Click',
      trackingLabel: 'send-email',
    };
    const data = JSON.stringify(payload);
    PostApiRef.current.postToParent(data);
    try {
      setState((pre) => ({
        ...pre,
        showSaveLaterModal: true,
        saveLaterRequest: {
          isLoading: true,
        },
      }));
      await ApiRef.current.postContractLink({
        email,
        locale: '',
      });

      setState((pre) => ({
        ...pre,
        saveLaterModalMode: 'automatic',
        saveLaterRequest: {
          isLoading: false,
        },
      }));
    } catch (e) {
      analytics?.trackError(e, { action: 'sendEmailLink', email });
      if (e.response.status === 400) {
        setState((pre) => ({
          ...pre,
          saveLaterModalMode: 'manual',
          saveLaterRequest: {
            isLoading: false,
          },
        }));
      } else {
        setState((pre) => ({
          ...pre,
          saveLaterModalMode: 'manual',
          saveLaterRequest: {
            isLoading: false,
            error: e.message,
          },
        }));
      }
    }
  };

  const closeSaveLaterModal = () => {
    const payload = {
      trackingEventName: 'Button Click',
      trackingLabel: 'close-save-later-modal',
    };
    const data = JSON.stringify(payload);
    PostApiRef.current.postToParent(data);
    setState((pre) => ({
      ...pre,
      showSaveLaterModal: false,
    }));
  };

  const handleManualSaveLaterSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    const formProps = Object.fromEntries(formData);

    sendEmailLink({
      email: formProps.email.toString(),
    });
  };

  useEffect(() => {
    loadContract();
  }, []);

  /**
     * Purpose: If the status is Completed, It redirect to contract complete page
     * It create the array of signed contract, available these to download on complete page
     */

  useEffect(() => {
    if (state.mode === SignPageState.SignComplete) {
      const signedContracts = state.currentContract.signedContracts.map((contract) => ({
        contractId: contract.id,
        contractProvider: contract.template.provider,
        contractType: contract.template.content.title,
        isClickwrap: !!contract.template.ironcladProps,
      }));
      appContext.setSignedContracts(signedContracts);

      const payload = {
        status: 'SigningComplete',
      };
      const data = JSON.stringify(payload);
      PostApiRef.current.postToParent(data);

      navigate(Routes.complete);
    }
  }, [state.mode]);
  return {
    state,
    onSignContract: signContract,
    onSetClickwrapParameters: setStateValues,
    onSendEmailLink: sendEmailLink,
    onCloseSaveLaterModal: closeSaveLaterModal,
    handleManualSaveLaterSubmit,
    onContractViewed: postContractViewed,
  };
};

export default useContract;
