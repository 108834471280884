import React from 'react';
import ContractSign from '../components/Contract';
import ContractComplete from '../components/Complete';
import ErrorPage from '../components/Error';
import PageExpiry from '../components/PageExpiry';

import LayoutFactory from '../layouts/LayoutFactory';
import MessageLayoutFactory from '../layouts/MessageLayout/MessageLayoutFactory';
import Page from '../components/Page/Page';

const routes = [
  {
    component: (
      <Page baseId='signing-page'>
        <LayoutFactory>
          <ContractSign />
        </LayoutFactory>
      </Page>
    ),
    path: '/',
  },
  {
    component: (
      <Page baseId='complete-page'>
        <LayoutFactory>
          <ContractComplete />
        </LayoutFactory>
      </Page>
    ),
    path: '/complete/*',
  },
  {
    component: (
      <Page baseId='error-page'>
        <MessageLayoutFactory>
          <ErrorPage />
        </MessageLayoutFactory>
      </Page>
    ),
    path: '/error/*',
  },
  {
    component: (
      <Page baseId='expired-page'>
        <MessageLayoutFactory>
          <PageExpiry />
        </MessageLayoutFactory>
      </Page>
    ),
    path: '/expire/*',
  },
];

export default routes;
