import { AxiosInstance, AxiosResponse } from 'axios';
import { AxiosFactory } from '../../utils';
import { Contract, PostContract, PostViewedContract } from '../../models/contract';
import { ContractActivity } from 'src/models/ContractActivity';
import { ContractPdf, Pdf, ContractPdfResponse } from '../../models/contractPdf';
import ContractLink from '../../models/contractLink';

import { IApiService } from './IApiService';
import ApiEndpoints from './ApiEndpoints';

class ApiService implements IApiService {
  private axios: AxiosInstance;

  readonly successStatus: number;

  constructor() {
    this.axios = AxiosFactory.axiosInstance();
    this.successStatus = 200;
  }

  postContract = async (contract: PostContract): Promise<Contract | null> => {
    const response = await this.axios.post<Contract>(ApiEndpoints.Contracts, contract);
    return this.parseResponse(response);
  };

  postContractView = async (contract: PostViewedContract): Promise<unknown> => {
    const response = await this.axios.post<unknown>(ApiEndpoints.ContractsStatus, contract);
    return this.parseResponse(response);
  };

  postContractActivity = async (activity: ContractActivity) => {
    fetch(ApiEndpoints.activityApiUrl, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(activity)
    })
      .catch(e => {
        console.error('Activity post failed.', e);
      })
  };

  fetchContract = async (): Promise<Contract | null> => {
    try {
      const response = await this.axios.get<Contract>(ApiEndpoints.Contracts);
      return this.parseResponse(response);
    } catch (e) {
      console.error('fetch contracts failed', e);

      await new Promise(resolve => setTimeout(resolve, 1000));

      const response = await this.axios.get<Contract>(ApiEndpoints.Contracts);
      return this.parseResponse(response);
    }
  };

  fetchIpAddress = (): Promise<string | null> => {
    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        console.warn('IP fetch timed out');
        resolve(null);
      }, 5000);

      fetch(ApiEndpoints.IpFetchEndpoint)
        .then(response => resolve(response?.text()))
        .catch(e => {
          console.warn('IP fetch failed', e);
          resolve(null);
        })
        .then(() => clearTimeout(timeout));
    });
  };

  postContractPdf = async (contractPdf: ContractPdf): Promise<ContractPdfResponse | null> => {
    const response = await this.axios.post<ContractPdfResponse>(ApiEndpoints.ContractsPdf, contractPdf);
    return this.parseResponse(response);
  };

  downloadPdf = async (pdf: Pdf): Promise<any> => {
    const instance = AxiosFactory.newInstance();
    const res = await instance.get(pdf.url, {responseType: 'blob'});
    return res.data;
  };

  postContractLink = async (contractLink: ContractLink): Promise<unknown | null> => {
    const response = await this.axios.post<unknown>(ApiEndpoints.ContractsLink, contractLink);
    return this.parseResponse(response);
  };

  parseResponse<T>(response: AxiosResponse<T>): T | null {
    return response?.status === this.successStatus ? response.data : null;
  }
}

export default ApiService;
