import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PersistenceService } from '../../services';

import routes from '../../routes/index';
import style from './App.scss';
import { AppContext } from '../../context/appContext';
import useAppContext from '../../context/useAppContext';
import { changeLang } from '../../i18n';
import { getElementValueById, getQueryStringValue, isInIframe, getLogoProps } from '../../utils/AppUtil';
import { type LocaleTypes } from 'src/lang/lang.type';
import StorageError from '../StorageError';
import { EN_GB, TEYA_GBR,
  EN_US,
  EN_CA,
  DE_DE,
  FI_FI,
  FR_CA,
  PL,
} from '../../utils/constants';
import { initCookieBanner } from '../../analytics/CookieBanner';
import useLocale from '../../hooks/useLocale';
import { useAnalytics } from '../../analytics';

interface TokenPayload {
  IsPrimary: boolean;
  PartnerCode: string;
  Locale: string;
  DisclaimerKey?: string
}

const IsPrimary = 'IsPrimary';
const PartnerCode = 'PartnerCode';
const Branding = 'Branding';
const LangLocale = 'Locale';
const LOGO = 'logo';
const LogoSrc = 'logoSrc';
const PhoneNumber = 'phoneNumber';
const QueryToken = 'token';
const ImpressumLink = 'impressumLink';
const PrivacyPolicyLink = 'privacyPolicyLink';
const TermsConditionsLink = 'termsConditionsLink';
const CookieNoticeLink = 'cookieNoticeLink';
const ApplicationId = 'application';
const SignerId = 'signer';
const tokenPayload: TokenPayload = { IsPrimary: false, PartnerCode: '', Locale: '' };

const CookieBannerTranslatedRegions = [
  EN_GB,
  EN_US,
  EN_CA,
  DE_DE,
  FI_FI,
  FR_CA,
  PL,
];

const App = (): JSX.Element => {
  const { appState } = useAppContext();
  const [showStorageError, setShowStorageError] = useState(false);
  const [ canUseAnalytics, setCanUseAnalytics ] = useState(false);
  const { getLocale } = useLocale();
  const analytics = useAnalytics();

  const loadAppVariables = () => {
    if (!PersistenceService) {
      setShowStorageError(true);
    }

    let token = PersistenceService?.getValue('idToken');
    const queryStringValue = getQueryStringValue(window.location.href, QueryToken);
    if (queryStringValue !== '') {
      token = queryStringValue;
    }
    PersistenceService?.setValue('idToken', token);

    tokenPayload.IsPrimary = JSON.parse(getElementValueById(IsPrimary.toLowerCase()));
    tokenPayload.PartnerCode = getElementValueById(Branding) || getElementValueById(PartnerCode);
    tokenPayload.Locale = getElementValueById(LangLocale);
    const logo = getElementValueById(LOGO);
    const logoSrc = getElementValueById(LogoSrc);
    const phoneNumber = getElementValueById(PhoneNumber);
    const impressumLink = getElementValueById(ImpressumLink);
    const privacyPolicyLink = getElementValueById(PrivacyPolicyLink);
    const termsConditionsLink = getElementValueById(TermsConditionsLink);
    const cookieNoticeLink = getElementValueById(CookieNoticeLink);
    const applicationId = getElementValueById(ApplicationId);
    const signerId = getElementValueById(SignerId);

    appState.setState('isPrimary', tokenPayload.IsPrimary ?? '');
    appState.setState('partnerCode', tokenPayload.PartnerCode ?? '');
    appState.setState('logo', getLogoProps(tokenPayload.PartnerCode, logo));
    appState.setState('logoSrc', logoSrc);
    appState.setState('isInIframe', isInIframe());
    appState.setState('phoneNumber', phoneNumber);
    appState.setState('impressumLink', impressumLink);
    appState.setState('privacyPolicyLink', privacyPolicyLink);
    appState.setState('termsConditionsLink', termsConditionsLink);
    appState.setState('cookieNoticeLink', cookieNoticeLink);
    appState.setState('disclaimerKey', getElementValueById('DisclaimerKey') ?? '');

    if (applicationId && signerId) {
      analytics?.setDataSource(getElementValueById('Source') ?? 'contracts-api');
      analytics?.identifyUser(applicationId, signerId);
    }
  };

  const setLocale = () => {
    let locale: LocaleTypes = tokenPayload.Locale as LocaleTypes;

    if (!locale && tokenPayload.PartnerCode === TEYA_GBR) {
      locale = EN_GB;
    } else if (!locale) {
      return;
    }

    if (CookieBannerTranslatedRegions.includes(locale)) {
      // Because we're in an iFrame we don't want to show the cookie banner - only in standalone journeys
      // For now that means no analytics in iFrames
      setCanUseAnalytics(!isInIframe());
    }

    changeLang(locale);
  };

  useEffect(() => {
    loadAppVariables();
    setLocale();
  }, []);

  useEffect(() => {
    if (canUseAnalytics) {
      initCookieBanner(getLocale, analytics);
    }
  }, [canUseAnalytics]);

  return (
    <div className={style.componentWrapper}>
      {showStorageError ?
        <StorageError/>
        :
        <AppContext.Provider value={appState}>
          <Router>
            <Routes>
              {routes.map((route) => (
                <Route element={route.component} key={route.path} path={route.path} />
              ))}
            </Routes>
          </Router>
        </AppContext.Provider>
      }
    </div>
  );
};

export default App;
