import { JWTPayload } from '../models/AppTypes';

export const partnerCodes = {
  default: 'Liberis',
  worldpay: 'worldpay',
  KlarnaSweden: 'KlarnaSweden',
} as const;

export type PartnerCodeTypes = keyof typeof partnerCodes;
export const parseJwt = (token: string): JWTPayload => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getPartnerIconSuffix = (partner: PartnerCodeTypes): string => {
  switch (partner) {
    case 'worldpay':
      return '-sharp-outlined';
    case 'KlarnaSweden':
      return '-rounded-outlined';
    default:
      return '-sharp-filled';
  }
};
export const getElementValueById = (name: string): string | null => {
  const element = document.getElementById(name);
  if (element) {
    return (element as HTMLInputElement).value;
  }
  return null;
};

export const isInIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getLogoProps = (partnerCode: string, logoId?: string) => {
  const resolvedLogoId = logoId ?? window.appConfig?.liberisLogo;

  switch (partnerCode) {
    case 'worldpay':
      return {
        logoId: resolvedLogoId,
        width: '150',
        isInternal: true,
      };
    case 'KlarnaSweden':
      return {
        logoId: resolvedLogoId,
        width: '350',
        isInternal: true,
      };
    default:
      return {
        logoId: resolvedLogoId,
        width: '150',
        isInternal: true,
      };
  }
};

export const getQueryStringValue = (url: string, val: string): string => {
  const queryUrl = url.split('?')[1];
  if (queryUrl) {
    let queryArray = queryUrl.split('&');
    if (queryArray) {
      queryArray = queryArray.filter((query: string) => query.split('=')[0] === val);
      if (queryArray.length > 0) {
        return queryArray[0].split('=')[1];
      }
    }
  }
  return '';
};

export const capitalize = (word: string) => (word && word[0].toUpperCase() + word.slice(1)) || '';

export const appendPageTitle = (titleSuffix: string) =>{
  if (!titleSuffix) {
    return;
  }
  
  let documentTitle = document.title;
  const hyphenIndex = documentTitle.indexOf('-');

  if (hyphenIndex >= 0) {
    documentTitle = documentTitle.slice(0, hyphenIndex - 1);
  }
  
  document.title = documentTitle.concat(`${documentTitle ? ' - ' : ''}${titleSuffix}`);
};