/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import React, { FC } from 'react';
import { Heading, Button } from 'liberis-component-library';
import { PageExpiryLinkProp } from './PageExpiry.types';
import styles from './PageExpiry.scss';
import useLocale from '../../hooks/useLocale';

const PageExpiry: FC<PageExpiryLinkProp> = ({ isLoading, isSent, onSendClick }): JSX.Element => {
  const { getLocale } = useLocale();

  const handleSendClick = () => {
    if (onSendClick) {
      onSendClick();
    }
    return false;
  };
  return (
    <>
      <Heading bold> {getLocale('ExpiryHeading')}</Heading>
      <Heading className={styles.headingCopy} heading='h2'>
        {getLocale('ExpiryTitle')}
      </Heading>
      <Button isLoading={isLoading} data-cy='newLink' disabled={isSent} color='primary' onClick={handleSendClick}>
        {isSent ? getLocale('ExpiryLinkSent') : getLocale('ExpiryNewLink')}{' '}
      </Button>
    </>
  );
};

export default PageExpiry;

