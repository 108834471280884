import mixpanel from 'mixpanel-browser';
import { Analytics } from './Analytics';
import { AnalyticsEvent, AnalyticsEventName } from './AnalyticsEvent';

export function createMixpanelAnalytics(): Analytics | null {
  if (!window?.appConfig?.mixpanelToken) {
    console.log('Mixpanel is not configured');
    return null;
  }

  console.log('Mixpanel is configured');

  const config = {
    track_pageview: true,
  };
  mixpanel.init(window.appConfig.mixpanelToken, config);

  let tracking = false;
  let eventQueue: AnalyticsEvent[] = [];
  let pendingUserProperties: { [key: string]: string } = {};
  let loaded = false;
  let signed = false;
  let mark = 0;

  const setDataSource = (dataSource: string) => {
    mixpanel.register({
      source: dataSource,
    });
  };

  const timeSinceMark = () => { return performance.now() - mark; };

  const trackNewOrReturningUser = (): void => {
    const firstTimeVisitDate = mixpanel.get_property('first_time_visit_date');

    mixpanel.register({
      user_visit: firstTimeVisitDate === undefined ? 'new' : 'returning',
    });

    mixpanel.register_once({
      first_time_visit_date: new Date().toISOString(),
    });
  };

  const sendAnalyticsEvents = (): void => {
    eventQueue.forEach((event) => {
      const propertiesWithTimestamp = {
        ...event.properties,
        time: event.timestamp,
      };
      try {
        mixpanel.track(event.eventName, propertiesWithTimestamp);
      } catch (error) {
        console.error('Error tracking event', event, error);
      }
    });
    eventQueue = [];
  };

  const enableTracking = () => {
    tracking = true;
    for (const [propertyName, value] of Object.entries(pendingUserProperties)) {
      mixpanel.people.set({
        [propertyName]: value,
      });
    }
    pendingUserProperties = {};
    sendAnalyticsEvents();
  };

  const track = (eventName: AnalyticsEventName, properties = {}): void => {
    const timestamp = new Date().getTime();
    eventQueue.push({
      eventName,
      properties: { ...properties },
      timestamp,
    });

    if (tracking) {
      sendAnalyticsEvents();
    }
  };

  const identifyUser = (applicationId: string, signerId: string) => {
    mixpanel.identify(`${applicationId}_${signerId}`);
    mixpanel.register({
      applicationId,
      signerId,
    });
    track(AnalyticsEventName.UserIdentified);
  };

  const trackContractDisplayed = () => {
    let duration = 0;
    if (!loaded) {
      duration = performance.now();
    } else if (signed) {
      duration = timeSinceMark();
    } else {
      return; // Debounce multiple calls, we only care about post-load and post-sign
    }

    track(AnalyticsEventName.ContractDisplayed, {
      'Is First Contract': !loaded,
      Delay: (duration / 1000).toFixed(3),
    });

    loaded = true;
    signed = false;
  };

  const trackCheckboxClick = (
    value: boolean,
    checkboxName: string,
  ) => {
    track(AnalyticsEventName.CheckboxClicked, {
      checkboxName,
      value,
    });
  };

  const trackSignClick = () => {
    track(AnalyticsEventName.SignClicked);
    signed = true;
    mark = performance.now();
  };

  const trackComplete = () => {
    let duration = 0;
    if (signed) {
      duration = timeSinceMark();
      signed = false;
    }
    track(AnalyticsEventName.Complete, { Delay: (duration / 1000).toFixed(3) });
  };

  const trackDownloadRequested = () => {
    track(AnalyticsEventName.DownloadRequested);
    mark = performance.now();
  };

  const trackDownloadStarted = () => {
    const duration = performance.now() - mark;
    track(AnalyticsEventName.DownloadStarted, { Delay: (duration / 1000).toFixed(3) });
  };

  const trackDownloadFailed = () => {
    const duration = performance.now() - mark;
    track(AnalyticsEventName.DownloadFailed, { Delay: (duration / 1000).toFixed(3) });
  };

  const trackApi = (apiName: string, duration: number, success: boolean) => {
    track(AnalyticsEventName.ApiCall, {
      Name: apiName,
      Delay: (duration / 1000).toFixed(3),
      Success: success,
    });
  };

  const setUserProperty = (propertyName: string, value: string) => {
    if (tracking) {
      mixpanel.people.set({
        [propertyName]: value,
      });
    } else {
      pendingUserProperties[propertyName] = value;
    }
  };

  const trackError = (error: Error | string, context?: Record<string, any>) => {};
  const trackEvent = (eventName: string, properties?: Record<string, any>) => {};

  return Object.freeze({
    setDataSource,
    trackNewOrReturningUser,
    identifyUser,
    enableTracking,
    trackContractDisplayed,
    trackCheckboxClick,
    trackSignClick,
    trackComplete,
    trackDownloadRequested,
    trackDownloadStarted,
    trackDownloadFailed,
    setUserProperty,
    trackApi,
    trackError,
    trackEvent,
  });
}
