export interface ContractCompleteProps {
  showDownloadErrorModal: boolean;
  isInIframe: boolean;
  contracts?: Array<SignedContract>;
  onDownloadClick?: (contract: SignedContract) => void;
  onContinueClick?: () => void;
}
export interface SignedContract {
  contractId: string;
  contractProvider?: string;
  contractType: string;
  isClickwrap: boolean;
}
export interface ApplicationContract {
  applicationId: string;
  applicantId: string;
}
export interface ContractCompleteHookState {
  showDownloadErrorModal: boolean;
  isInIframe: boolean;
  contracts?: Array<SignedContract>;
  onDownloadClick?: (contract: SignedContract) => void;
  onContinueClick?: () => void;
}

export const initialContract: ContractCompleteHookState = {
  showDownloadErrorModal: false,
  isInIframe: false,
  contracts: [],
};
