/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import { Logo, Link } from 'liberis-component-library';

import styles from './KlarnaFooter.scss';
import { AppContext } from '../../../context/appContext';
import useLocale from '../../../hooks/useLocale';

const KlarnaFooter = (): JSX.Element => {
  const { getLocale } = useLocale();
  const { privacyPolicyLink, termsConditionsLink, logo } = useContext(AppContext);

  const propsFooter = {
    links: [
      {
        href: privacyPolicyLink,
        children: getLocale('KlarnaDisclaimers'),
      },
      {
        href: termsConditionsLink,
        children: getLocale('KlarnaTerm'),
      },
    ],
  };

  return (
    <div className={styles.footerRow}>
      <div>
        <Logo className={styles.logo} {...logo} />
      </div>
      <div className={styles.footerText}>{getLocale('KlarnaFooterText')}</div>
      <div className={styles.footerLinksRow}>
        <Link className={styles.linkButton} href={propsFooter.links[1].href}>
          {propsFooter.links[1].children}
        </Link>
        <Link className={styles.linkButton} href={propsFooter.links[0].href}>
          {propsFooter.links[0].children}
        </Link>
      </div>
    </div>
  );
};

export default KlarnaFooter;

