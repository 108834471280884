/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import {
  SignedContract,
  ApplicationContract,
} from '../components/Complete/ContractComplete.types';

import { AppContextKeys, AppContextType, initialContext } from './appContext';

const useAppContext = (): { appState: AppContextType } => {
  const [state, setState] = useState<AppContextType>(initialContext);
  const [contracts, setSignedContracts] = useState<Array<SignedContract>>([]);
  const [applicationContract, setApplicationContract] = useState<ApplicationContract>();

  const handleUpdateState = (key: AppContextKeys, value: string | boolean) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleSignedContracts = (signedContracts: Array<SignedContract>) => {
    setSignedContracts(signedContracts);
  };

  const handleApplicationContract = (
    applicationContractToSet: ApplicationContract,
  ) => {
    setApplicationContract(applicationContractToSet);
  };

  return {
    appState: {
      ...state,
      signedContracts: contracts,
      applicationContract,
      setState: handleUpdateState,
      setSignedContracts: handleSignedContracts,
      setApplicationContract: handleApplicationContract,
    },
  };
};

export default useAppContext;
