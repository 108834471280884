import React, { ProviderProps, useMemo } from 'react';
import { Analytics } from './Analytics';
import { AnalyticsContext } from './AnalyticsContext';
import { createAnalyticsProxy } from './AnalyticsProxy';
import { createMixpanelAnalytics } from './Mixpanel';
import { createDatadogAnalytics } from './Datadog';

export interface AnalyticsProviderProps
  extends Omit<ProviderProps<Analytics | undefined>, 'value'>
{}

export function MixpanelAnalyticsProvider({
  children,
}: Readonly<AnalyticsProviderProps>) {
  const provider = useMemo(
    () => {
      const providers = [
        createMixpanelAnalytics(),
        createDatadogAnalytics(),
      ];
      return createAnalyticsProxy(providers.filter(target => !!target));
    },
    [],
  );

  return <AnalyticsContext.Provider value={provider}>{children}</AnalyticsContext.Provider>;
}