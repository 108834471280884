// Partners
export const KLARNA_CODE = 'KlarnaSweden';
export const VAGARO_USA = 'VAGARO_USA';
export const VAGARO_GBR = 'VAGARO_GBR';
export const VAGARO_CAN = 'VAGARO_CAN';
export const TEYA_GBR = 'TEYA_GBR';


// Locales
export const EN_GB = 'en-GB';
export const EN_US = 'en-US';
export const EN_CA = 'en-CA';
export const SV_SE = 'sv-SE';
export const DA_DK = 'da-DK';
export const DE_DE = 'de-DE';
export const FI_FI = 'fi-FI';
export const SK_SK = 'sk-SK';
export const CS_CZ = 'cs-CZ';
export const FR_CA = 'fr-CA';
export const IS_IS = 'is';
export const PL = 'pl';
