import { createContext } from 'react';
import { SignedContract, ApplicationContract } from '../components/Complete/ContractComplete.types';

export interface AppContextType {
  partnerCode: string;
  isPrimary: boolean;
  isInIframe: boolean;
  logo: {
    logoId: string;
    width: string;
    isInternal: boolean;
    alt?: string;
  };
  logoSrc?: string;
  phoneNumber: string;
  impressumLink?: string;
  privacyPolicyLink: string;
  termsConditionsLink: string;
  cookieNoticeLink: string;
  disclaimerKey: string;
  signedContracts: Array<SignedContract>;
  applicationContract: ApplicationContract;

  setState: (key: AppContextKeys, val: AppContextValues) => void;
  setSignedContracts: (contracts: Array<SignedContract>) => void;
  setApplicationContract: (applicationContract: ApplicationContract) => void;
}
type AppContextKeys = keyof AppContextType;
type AppContextValues = AppContextType[keyof AppContextType];
export const initialContext: AppContextType = {
  partnerCode: '',
  isPrimary: false,
  isInIframe: false,
  logo: {
    logoId: '',
    width: '',
    isInternal: false,
  },
  phoneNumber: '',
  impressumLink: '',
  privacyPolicyLink: '',
  termsConditionsLink: '',
  cookieNoticeLink: '',
  signedContracts: [],
  applicationContract: { applicationId: '', applicantId: '' },
  disclaimerKey: '',

  setState: () => {},
  setSignedContracts: () => {},
  setApplicationContract: () => {},
};

export const AppContext = createContext(initialContext);
export { AppContextKeys, AppContextValues };
