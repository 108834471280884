import { ClickwrapMetadata } from 'src/components/Contract/ContractSign.types';
import { ContractFooter } from './ContractFooter';
import { ContractsObject } from './contractsObject';

const SignContractStatusType = {
  Complete: 'Completed',
  UnComplete: 'UnComplete',
} as const;

export type SignContractStatus =
  typeof SignContractStatusType[keyof typeof SignContractStatusType];

export const ContractDocumentType = {
  personalGuarantee: 'Personal Guarantee',
  financeAgreement: 'Finance Agreement',
  renewalTerms: 'Renewal Terms',
  mandate: 'Mandate',
} as const;

export type ContractType =
  typeof ContractDocumentType[keyof typeof ContractDocumentType];

export interface Contract {
  applicationId: string;
  applicantId: string;
  status: SignContractStatus;
  unsignedContract: ContractsObject;
  signedContracts: Array<ContractsObject>;
}

export interface PostViewedContract {
  chainStepId: string;
  newStatus: string;
  clickwrapMetadata: ClickwrapMetadata;
}

export interface PostContract {
  locale: string;
  chainStepId: string;
  ipAddress: string;
  templateId: string;
  documentType: string;
  signedCopy: PostContractSigned;
  name: string;
  date: string;
  isPrimary: boolean;
  isClickwrap: boolean;
  partnerCode: string;
  applicationId: string;
  clickwrapMetadata: ClickwrapMetadata;
  metadata?: {
    additionalProp1: string;
  };
}

export interface PostContractSigned {
  title: string;
  header: string;
  body: string;
  footer: Array<ContractFooter>;
}
