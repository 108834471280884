import React from 'react';
import LiberisMessageLayout from './LiberisMessageLayout';
import MessageLayout from './MessageLayout';
import { isInIframe } from '../../utils/AppUtil';
import { ComponentWithChildren } from '../../models/ComponentWithChildren';

const MessageLayoutFactory = ({ children }: ComponentWithChildren) => {
  const isIFrame = isInIframe();
  if (isIFrame) {
    return <MessageLayout>{children}</MessageLayout>;
  }
  return <LiberisMessageLayout>{children}</LiberisMessageLayout>;
};

export default MessageLayoutFactory;
