/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useEffect, useMemo, useContext } from 'react';
import { VerticalOneColumn, Link, Text, List, Heading, Button } from 'liberis-component-library';
import { useNavigate } from 'react-router-dom';
import { getPartnerIconSuffix, PartnerCodeTypes } from '../../utils/AppUtil';
import styles from './ContractComplete.scss';
import { ContractCompleteProps, SignedContract } from './ContractComplete.types';
import useLocale from '../../hooks/useLocale';
import { AppContext } from '../../context/appContext';
import { useAnalytics } from '../../analytics';

const ContractComplete: FC<ContractCompleteProps> = ({
  isInIframe,
  contracts,
  onDownloadClick,
  onContinueClick,
}): JSX.Element => {
  const appContext = useContext(AppContext);
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { getLocale } = useLocale();
  const handleContinueClick = () => {
    onContinueClick();
  };

  const handleDownloadClick = (contract: SignedContract) => (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (onDownloadClick) onDownloadClick(contract);
    return false;
  };

  const getLinkButton = (contract: SignedContract) => (
    <Link href='/' onClick={handleDownloadClick(contract)} className={styles.linkButton} data-testid='download'>
      {getLocale('Download')}
    </Link>
  );

  const contractsList = useMemo(
    () =>
      contracts.map((item) => ({
        icon: {
          title: item.contractType,
          width: '6.25rem',
          height: '6.25rem',
          viewBox: '0 0 100 100',
          fill: 'none',
          icon: `sign-contract${getPartnerIconSuffix(appContext.partnerCode as PartnerCodeTypes)}`,
        },
        heading: item.contractType,
        text: getLinkButton(item),
      })),
    [contracts, appContext],
  );
  useEffect(() => {
    if (contracts.length === 0) {
      navigate('/');
    }
    analytics?.trackComplete();
  }, []);
  return (
    <div id='container' className={styles.container}>
      <VerticalOneColumn>
        <Heading heading='h2'>{getLocale('CompletePageTitle')}</Heading>
        {!isInIframe && <Text> {getLocale('ThankyouMessage')} </Text>}
        <List iconHeadingTextOptions={contractsList} />

        {isInIframe && (
          <Button className={styles.continue} onClick={handleContinueClick} color='primary'>
            {getLocale('Continue')}
          </Button>
        )}
      </VerticalOneColumn>
    </div>
  );
};

export default ContractComplete;

