import React, { useContext } from 'react';
import { Text, Heading, Modal, Button, Loading, Link, InputField } from 'liberis-component-library';
import { AppContext } from '../../context/appContext';
import styles from './ContractSign.scss';
import { SaveLaterModalMode } from './ContractSign.types';
import useLocale from '../../hooks/useLocale';

interface Props {
  onCloseModalClick: () => void;
  onManualSaveLaterSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isLinkSent: boolean;
  isFailureOccur: boolean;
  mode: SaveLaterModalMode;
}
const SaveLaterModal = ({ onCloseModalClick, onManualSaveLaterSubmit, isLinkSent, isFailureOccur, mode }: Props) => {
  const { getLocale } = useLocale();

  const appContext = useContext(AppContext);

  const handleContinueClick = () => {
    onCloseModalClick();
  };

  const AutomaticSaveLaterContent = () => (
    <>
      <Heading heading='h2'>{getLocale('ModalTitle')}</Heading>
      <Text>{getLocale('ModalMessage')}</Text>
      <Button data-modalclose type='submit' onClick={handleContinueClick}>
        {getLocale('Continue')}
      </Button>
    </>
  );

  const ManualSaveLaterContent = () => (
    <>
      <Heading heading='h2'>{getLocale('ModalTitle')}</Heading>
      <Text>{getLocale('ManualModalMessage')}</Text>
      <form onSubmit={onManualSaveLaterSubmit}>
        <InputField
          type='string'
          name='email'
          title='email'
          uiSchema={{
            properties: {
              isFullWidth: true,
              placeholder: 'Email',
              ariaProps: {
                required: true,
                type: 'email',
              },
            },
          }}
        />

        <Button type='submit'>{getLocale('Submit')}</Button>
      </form>
    </>
  );

  const FailureContent = () => (
    <>
      <Heading heading='h2'>{getLocale('SaveFailed')}</Heading>
      <Text>
        {getLocale('SFMessageCallUs')}{' '}
        <Link href={`tel:+${appContext.phoneNumber}`} className={styles.linkButton}>
          {appContext.phoneNumber}
        </Link>{' '}
        {getLocale('SFMessageCustomerService')}
      </Text>
    </>
  );

  return (
    <Modal closeModalClickHandler={onCloseModalClick} pageContentSelector='#root'>
      <div className={styles.modalBody} aria-busy='true' aria-live='polite' aria-label={getLocale('Loading')} tabIndex={-1}>
        <Loading isLoading={isLinkSent} loaderText='' />
        {isFailureOccur && <FailureContent />}
        {!isFailureOccur && !isLinkSent && mode === 'automatic' && <AutomaticSaveLaterContent />}
        {!isFailureOccur && !isLinkSent && mode === 'manual' && <ManualSaveLaterContent />}
      </div>
    </Modal>
  );
};

export default SaveLaterModal;

