import { StorageTypeKeys } from '../../models/AppTypes';

import { IPersistenceService } from './IPersistenceService';

class PersistenceService implements IPersistenceService {
  constructor(private storage: Storage = sessionStorage) {
    this.storage = storage;
  }

  getValue(key: StorageTypeKeys): string {
    return this.storage.getItem(key);
  }

  setValue(key: StorageTypeKeys, value: string): void {
    this.storage.setItem(key, value);
  }

  removeValue(key: StorageTypeKeys): void {
    this.storage.removeItem(key);
  }
}

let persistenceService: IPersistenceService | null = null;
try {
  persistenceService = new PersistenceService();
} catch (e) {
  console.warn('Error loading PersistenceService', e);
  persistenceService = null;
}

export default persistenceService;
