/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useRef, useContext } from 'react';
import { Icon, Heading, Text, Link, Button } from 'liberis-component-library';
import PostService from '../../services/PostService';
import { AppContext } from '../../context/appContext';
import styles from './ErrorPage.scss';
import useLocale from '../../hooks/useLocale';
import { getPartnerIconSuffix, PartnerCodeTypes } from '../../utils/AppUtil';

interface Props {
  heading: string;
  title: string;
  contactNo: string;
}
const ErrorMessage: FC<Props> = ({ heading, title, contactNo }) => {
  const appContext = useContext(AppContext);
  const PostApiRef = useRef<PostService>(new PostService());
  const { getLocale } = useLocale();

  const handleBackToJourneyClick = () => {
    const payload = {
      status: 'ContractFailure',
    };
    const data = JSON.stringify(payload);
    PostApiRef.current.postToParent(data);
  };
  return (
    <div className={styles.errorBody}>
      <Icon
        width='6.25rem'
        height='6.25rem'
        viewBox='0 0 100 100'
        icon={`not-the-owner-circle${getPartnerIconSuffix(appContext.partnerCode as PartnerCodeTypes)}`}
      />
      <Heading heading='h2'>{heading}</Heading>
      <Text>{title}</Text>
      <Link href={`tel:+${contactNo}`} className={styles.linkButton}>
        {contactNo}
      </Link>
      <div className={styles.backTo}>
        <Button onClick={handleBackToJourneyClick} color='primary'>
          {getLocale('Back')}
        </Button>
      </div>
    </div>
  );
};

export default ErrorMessage;

