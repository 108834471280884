import { Locales } from 'src/lang/lang.type';
import * as CookieConsent from 'vanilla-cookieconsent';
import { Analytics } from './Analytics';

interface InterpolationValues {
  [key: string]: any
}

export function initCookieBanner(
  getLocale: (key: Locales | string[], options?: InterpolationValues) => string,
  analytics: Analytics | undefined,
): void {
  if (!document.getElementById('cc--main')) {
    CookieConsent.run({
      autoShow: true,
      autoClearCookies: true,
      //page_scripts: true,
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          enabled: false,
          readOnly: false,
          autoClear: {
            cookies: [
              { name: /^_ga/ },
              { name: /_gid/ },
              { name: /^mp_/ },
              { name: /^_dd/ },
            ],
          },
        },
      },
      onConsent: function (param: { cookie: CookieConsent.CookieValue }) {
        if (param.cookie.categories.includes('analytics')) {
          analytics?.enableTracking();
        }
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: getLocale('cookie-title'),
              description: getLocale('cookie-description'),
              acceptAllBtn: getLocale('cookie-accept-button'),
              acceptNecessaryBtn: getLocale('cookie-modal-reject-button'),
              showPreferencesBtn: getLocale('cookie-settings-button'),
            },
            preferencesModal: {
              title: getLocale('cookie-modal-title'),
              acceptAllBtn: getLocale('cookie-modal-accept-button'),
              acceptNecessaryBtn: getLocale('cookie-modal-reject-button'),
              savePreferencesBtn: getLocale('cookie-modal-save-settings-button'),
              sections: [
                {
                  title: getLocale('cookie-modal-preferences-title'),
                  description: getLocale('cookie-modal-preferences-description'),
                },
                {
                  title: getLocale('cookie-modal-necessary-cookies-title'),
                  description: getLocale('cookie-modal-necessary-cookies-description'),
                  linkedCategory: 'necessary',
                },
                {
                  title: getLocale('cookie-modal-analytics-cookies-title'),
                  description: getLocale('cookie-modal-analytics-cookies-description'),
                  linkedCategory: 'analytics',
                  cookieTable: {
                    headers: {
                      col1: getLocale('cookie-modal-table-name'),
                      col2: getLocale('cookie-modal-table-domain'),
                      col3: getLocale('cookie-modal-table-expiration'),
                      col4: getLocale('cookie-modal-table-description'),
                      col5: getLocale('cookie-modal-table-type'),
                    },
                    body: [
                      {
                        col1: getLocale('cookie-modal-table-name-google-ga'),
                        col2: getLocale('cookie-modal-table-domain-google-ga'),
                        col3: getLocale('cookie-modal-table-expiration-google-ga'),
                        col4: getLocale('cookie-modal-table-description-google-ga'),
                        col5: getLocale('cookie-modal-table-type-google-ga'),
                      },
                      {
                        col1: getLocale('cookie-modal-table-name-google-gid'),
                        col2: getLocale('cookie-modal-table-domain-google-gid'),
                        col3: getLocale('cookie-modal-table-expiration-google-gid'),
                        col4: getLocale('cookie-modal-table-description-google-gid'),
                        col5: getLocale('cookie-modal-table-type-google-gid'),
                      },
                      {
                        col1: getLocale('cookie-modal-table-name-mixpanel'),
                        col2: getLocale('cookie-modal-table-domain-mixpanel'),
                        col3: getLocale('cookie-modal-table-expiration-mixpanel'),
                        col4: getLocale('cookie-modal-table-description-mixpanel'),
                        col5: getLocale('cookie-modal-table-type-mixpanel'),
                      },
                    ],
                  },
                },
                {
                  title: getLocale('cookie-modal-more-information-title'),
                  description: getLocale('cookie-modal-more-information-description'),
                },
              ],
            },
          },
        },
      },
    });
  }
}
