import enGB from './en.json';
import enUS from './en-us.json';
import enCA from './en-ca.json';
import svSE from './sv.json';
import daDK from './da.json';
import deDE from './de.json';
import fiFI from './fi.json';
import frCA from './fr_CA.json';
import csCZ from './cs.json';
import skSK from './sk.json';
import isIS from './is.json';
import pl from './pl.json';
import {
  EN_GB,
  EN_US,
  EN_CA,
  SV_SE,
  DA_DK,
  DE_DE,
  FI_FI,
  SK_SK,
  CS_CZ,
  FR_CA,
  IS_IS,
  PL,
} from '../utils/constants';

export interface Locale {
  [EN_GB]: any;
  [EN_US]: any;
  [EN_CA]: any;
  [SV_SE]: any;
  [DA_DK]: any;
  [DE_DE]: any;
  [FI_FI]: any;
  [SK_SK]: any;
  [CS_CZ]: any;
  [FR_CA]: any;
  [IS_IS]: any;
  [PL]: any;
}

export const locale: Locale = {
  [EN_GB]: enGB,
  [EN_US]: enUS,
  [EN_CA]: enCA,
  [SV_SE]: svSE,
  [DA_DK]: daDK,
  [DE_DE]: deDE,
  [FI_FI]: fiFI,
  [SK_SK]: skSK,
  [CS_CZ]: csCZ,
  [FR_CA]: frCA,
  [IS_IS]: isIS,
  [PL]: pl,
};

export type LocaleTypes = keyof Locale;
export type Locales = keyof typeof enGB;
