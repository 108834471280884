import { useTranslation } from 'react-i18next';
import { Locales } from '../lang/lang.type';

interface InterpolationValues {
  [key: string]: any
}

const useLocale = (): { getLocale: (key: Locales | string[], options?: InterpolationValues) => string } => {
  const { t } = useTranslation();

  const getValue = (key: Locales | string[], options?: InterpolationValues): string => t(key, options) as string;
  return {
    getLocale: getValue,
  };
};

export default useLocale;
