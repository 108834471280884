/* eslint-disable import/no-extraneous-dependencies */
import { useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Routes } from '../models/AppTypes';
import { HttpStatusCode } from '../utils';
import { isInIframe } from '../utils/AppUtil';
import PostService from '../services/PostService';

/**
 * Purpose: To manage all the exceptions and set the route base on error code
 */

const useExceptionFlow = (): {
  exceptionFlow: (e: Error, when?: string) => void;
} => {
  const navigate = useNavigate();
  const PostApiRef = useRef<PostService>(new PostService());

  const isInIframeMemo = useMemo(() => isInIframe(), []);
  const exceptionFlow = (e: Error, when?: string) => {
    const postContractFailureMessage = () => {
      const payload = {
        status: 'ContractFailure',
      };
      const data = JSON.stringify(payload);
      PostApiRef.current.postToParent(data);
    };
    const handleErrorForUser = () => {
      if (isInIframeMemo) {
        postContractFailureMessage();
      }

      navigate(Routes.error);
    };

    if (axios.isAxiosError(e)) {
      if (!e.response) {
        handleErrorForUser();
        return;
      }
      const requestError = e.response.status === HttpStatusCode.BAD_REQUEST
        || e.response.status === HttpStatusCode.INTERNAL_SERVER_ERROR
        || e.response.status === HttpStatusCode.NOT_FOUND;
      const unauthorized = e.response.status === HttpStatusCode.UNAUTHORIZED;
      const errorInSendLink = when === 'ErrorInSendLink';

      if (requestError) handleErrorForUser();

      if (unauthorized) {
        if (errorInSendLink) {
          handleErrorForUser();
        } else {
          navigate(Routes.expire);
        }
      }
    }
  };
  return { exceptionFlow };
};

export default useExceptionFlow;
