import React, { FC, useEffect, useState } from 'react';
import { ContractBody } from '../ContractSign.types';
import styles from './HtmlContractRenderer.scss';
import useLocale from '../../../hooks/useLocale';

export interface HtmlContractRendererProps {
  contract: ContractBody;
  onEventAffectingHeight: (event: string) => void;
  onContractViewed?: (downloadUrl: string) => void;
  onValidHandler: (complete: boolean) => void;
  onSaveLaterClick?: () => void;
}

const HtmlContractRenderer: FC<HtmlContractRendererProps> = ({
  contract,
  onEventAffectingHeight,
  onContractViewed,
  onValidHandler,
  onSaveLaterClick,
}): JSX.Element => {
  const { getLocale } = useLocale();
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    if (contract) {
      onEventAffectingHeight('rendered');
      onContractViewed?.('');
    }
  }, [contract]);
  
  const handleSaveLaterClick = (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onSaveLaterClick();
    return false;
  };

  const onAcceptanceChange = () => {
    if (isAccepted) {
      setIsAccepted(false);
      onValidHandler(false);
    } else {
      setIsAccepted(true);
      onValidHandler(true);
    }
  };

  return (
    <div className={styles.htmlTemplateContainer}>
      <div>
        <div className={styles.contractHeader}>
          <a href='#' onClick={handleSaveLaterClick} data-testid='saveForLater'>
            {getLocale('SaveForLater')}
          </a>
          <a href={'/download?contractId=' + contract.id} target='_blank' rel='noreferrer'>{getLocale('Download')}</a>
        </div>
        <div className={styles.contractBody}
          data-dd-privacy='mask'
          aria-label='contract'
          dangerouslySetInnerHTML={{ __html: contract.htmlContractProps!.html }}
        ></div>
        <label htmlFor='contractAcceptance' className={styles.contractAcceptance}>
          <input id='contractAcceptance' className={styles.contractAcceptance}
            type='checkbox'
            onChange={onAcceptanceChange}
          />
          <div>
            {contract.htmlContractProps!.acceptanceText}
          </div>
        </label>
      </div>
    </div>
  );
};

export default HtmlContractRenderer;
