const ApiEndpoints = {
  Contracts: window?.appConfig?.contractPath ?? '',
  ContractsPdf: `${window?.appConfig?.contractPath}/pdf`,
  ContractsLink: `${window?.appConfig?.contractPath}/link`,
  ContractsStatus: `${window?.appConfig?.contractPath}/status`,
  IpFetchEndpoint: 'https://api.ipify.org/',
  activityApiUrl: "https://pactsafe.io/send"
};

export default ApiEndpoints;
