/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import { Footer } from 'liberis-component-library';
import style from './liberis.scss';
import Header from '../components/Header';
import KlarnaFooter from '../components/Footer/Klarna';
import useLocale from '../hooks/useLocale';
import { AppContext } from '../context/appContext';
import { partnerCodes } from '../utils/AppUtil';
import { ComponentWithChildren } from '../models/ComponentWithChildren';

const Liberis = ({ children }: ComponentWithChildren): JSX.Element => {
  const appContext = useContext(AppContext);
  const { getLocale } = useLocale();
  const FooterText = getLocale([appContext.disclaimerKey, 'FooterText']);
  const Impressum = getLocale('Imprint');
  const PrivacyPolicy = getLocale('PrivacyPolicy');
  const TermsConditions = getLocale('TermsConditions');
  const CookieNotice = getLocale('CookieNotice');

  const propsFooter = {
    text: FooterText,
    links: [
      {
        href: appContext.privacyPolicyLink,
        children: PrivacyPolicy,
      },
      {
        href: appContext.termsConditionsLink,
        children: TermsConditions,
      },
      {
        href: appContext.cookieNoticeLink,
        children: CookieNotice,
      },
    ],
  };

  if (appContext.impressumLink && Impressum) {
    propsFooter.links.unshift({
      href: appContext.impressumLink,
      children: Impressum,
    });
  }

  return (
    <div className={style.container}>
      <div className={style.contract}>
        <div className={style.left}>
          <Header />
        </div>
        {children}
      </div>
      <div className={style.footer}>
      {
        appContext.partnerCode === partnerCodes.KlarnaSweden
          ? <KlarnaFooter />
          : <Footer {...propsFooter} />
      }
      </div>
    </div>
  );
};

export default Liberis;
