import React, { FC, useContext } from 'react';
import { AppContext } from '../../context/appContext';
import style from './Page.scss';

export interface PageProps {
  baseId: string;
  children?: React.ReactNode
}

const Page: FC<PageProps> = ({
  baseId,
  children,
}): JSX.Element => {
  const { isInIframe } = useContext(AppContext);

  const id = isInIframe ? `${baseId}-iframe` : baseId;

  return (
    <span className={style.wrapperSpan} id={id}>
      {children}
    </span>
  );
};

export default Page;
