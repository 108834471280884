import React, { useContext, useEffect } from 'react';
import ErrorMessage from './ErrorMessage';
import { AppContext } from '../../context/appContext';
import useLocale from '../../hooks/useLocale';
import { appendPageTitle } from '../../utils/AppUtil';

const ErrorPage = (): JSX.Element => {
  const { getLocale } = useLocale();
  const appContext = useContext(AppContext);

  const errorProps = {
    heading: getLocale('WrongHeading'),
    title: getLocale('WrongTitle'),
    contactNo: appContext.phoneNumber,
  };
  
  useEffect(() => {
    appendPageTitle('Error');
  }, []);

  return <ErrorMessage {...errorProps} />;
};

export default ErrorPage;
