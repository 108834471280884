/* eslint-disable no-magic-numbers */
enum ExceptionType {
  NetworkError = 'NetworkError',
  Error = 'Error',
}

enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}
class NetworkError extends Error {
  constructor(public message: string) {
    super(message);
    this.name = ExceptionType.NetworkError;
    this.stack = (<any> new Error()).stack;
  }
}

export { NetworkError, ExceptionType, HttpStatusCode };
