import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { locale, LocaleTypes } from './lang/lang.type';
import { EN_GB } from './utils/constants';

const resources: Resource = {};
Object.keys(locale).forEach((key: LocaleTypes) => {
  resources[key] = {
    translation: locale[key],
  };
});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: EN_GB,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const changeLang = (lng: LocaleTypes): void => {
  i18n.changeLanguage(lng.toString());
};