import React, { FC } from 'react';
import LegacyContractRenderer from './LegacyContractRenderer';
import IroncladContractRenderer from './IroncladContractRenderer';
import HtmlContractRenderer from './HtmlContractRenderer';
import { ContractBody } from '../ContractSign.types';

export interface ContractRendererProps {
  contract: ContractBody;
  onValidHandler: (complete: boolean) => void;
  onInvalidHandler: () => void;
  onSetHandler:(parameter: string, value: object, context: any) => void;
  onEventAffectingHeight: (event: string) => void;
  onContractViewed?: (downloadUrl: string) => void;
  onSaveLaterClick?: () => void;
}

const ContractRenderer: FC<ContractRendererProps> = ({
  contract,
  onValidHandler,
  onInvalidHandler,
  onSetHandler,
  onEventAffectingHeight,
  onContractViewed,
  onSaveLaterClick,
}): JSX.Element => {

  switch (contract.provider) {
    case 'html':
      return <HtmlContractRenderer
        contract={contract}
        onEventAffectingHeight={onEventAffectingHeight}
        onContractViewed={onContractViewed}
        onValidHandler={onValidHandler}
        onSaveLaterClick={onSaveLaterClick}
      />;

    default:
      return contract.ironcladProps
        ? <IroncladContractRenderer
            contract={contract}
            onValidHandler={onValidHandler}
            onInvalidHandler={onInvalidHandler}
            onSetHandler={onSetHandler}
            onEventAffectingHeight={onEventAffectingHeight}
            onContractViewed={onContractViewed}
          />
        : <LegacyContractRenderer
            contract={contract}
            onValidHandler={onValidHandler}
          />;
  }
};

export default ContractRenderer;
