import { Analytics } from './Analytics';

export function createAnalyticsProxy(targets: Analytics[]): Analytics {
  return Object.freeze({
    setDataSource: (dataSource: string) => {
      console.log('Data source is ' + dataSource);
      targets.forEach(target => target.setDataSource(dataSource));
    },
  
    trackNewOrReturningUser: () =>
      targets.forEach(target => target.trackNewOrReturningUser()),

    identifyUser: (applicationId: string, signerId: string) =>
      targets.forEach(target => target.identifyUser(applicationId, signerId)),

    enableTracking: () =>
      targets.forEach(target => target.enableTracking()),

    trackContractDisplayed: () => targets.forEach(target => target.trackContractDisplayed()),

    trackCheckboxClick: (value: boolean, checkboxName: string) =>
      targets.forEach(target => target.trackCheckboxClick(value, checkboxName)),

    trackSignClick: () =>
      targets.forEach(target => target.trackSignClick()),

    trackComplete: () =>
      targets.forEach(target => target.trackComplete()),

    trackDownloadRequested: () => targets.forEach(target => target.trackDownloadRequested()),

    trackDownloadStarted: () => targets.forEach(target => target.trackDownloadStarted()),

    trackDownloadFailed: () => targets.forEach(target => target.trackDownloadFailed()),

    trackApi: (apiName: string, duration: number, success: boolean) =>
      targets.forEach(target => target.trackApi(apiName, duration, success)),

    setUserProperty: (propertyName: string, value: string) =>
      targets.forEach(target => target.setUserProperty(propertyName, value)),
  });
}
