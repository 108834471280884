import axios, { AxiosInstance } from 'axios';
import persistenceService from '../services/storage/PersistenceService';

const getAxiosInstance = (): AxiosInstance => axios.create({
  baseURL: window.appConfig.apiUrl,
  timeout: window.appConfig.apiTimeOut,
});

// eslint-disable-next-line import/prefer-default-export
export class AxiosFactory {
  static axiosInstance = (): AxiosInstance => {
    const instance = getAxiosInstance();
    instance.interceptors.request.use((config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${persistenceService?.getValue(
        'idToken',
      )}`;
      return config;
    });
    return instance;
  };

  static newInstance = (): AxiosInstance => axios.create({});
}
