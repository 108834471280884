import { Contract, ContractDocumentType, ContractType } from '../../models/contract';
import { ContractFooter } from '../../models/ContractFooter';

export interface ContractSignProps {
  title: string;
  subTitle: string;
  footerAlerts: Array<ContractFooter>;
  contract: ContractBody;
  mode: SignPageState;
  showSaveLaterModal: boolean;
  documentType: ContractType;
  onSignClick?: () => void;
  onSetClickwrapParameters?: (parameter: string, value: object, context: any) => void;
  onSaveLaterClick?: () => void;
  onContractViewed?: (downloadUrl: string) => void;
}
export interface ClickWrapProps {
  groupKey?: string;
  filter?: string;
  signer?: string;
}

export interface HtmlContractProps {
  html: string;
  acceptanceText: string;
}

export interface ContractBody {
  id?: string;
  provider?: string;
  content?: string;
  htmlContractProps?: HtmlContractProps;
  ironcladProps?: ClickWrapProps;
  renderData?: Record<string, string>;
}

export interface ClickwrapMetadata {
  payload: object;
  downloadUrl: string;
}

export enum SignPageState {
  Loading = 1,
  Loaded,
  SignComplete,
  Error,
}

export type SaveLaterModalMode = 'manual' | 'automatic';

export interface ContractSignHookState {
  title: string;
  subTitle: string;
  footerAlerts: Array<ContractFooter>;
  contract: ContractBody;
  clickwrapMetadata: ClickwrapMetadata;
  mode: SignPageState;
  documentType: ContractType;
  onSignClick?: () => void;
  currentContract?: Contract;
  showSaveLaterModal: boolean;
  saveLaterModalMode: SaveLaterModalMode;
  saveLaterRequest: {
    data?: any;
    isLoading?: boolean;
    error?: string | null;
  };
}

export const initialContract: ContractSignHookState = {
  title: '',
  subTitle: '',
  footerAlerts: [],
  contract: {
    content: '',
  },
  clickwrapMetadata: {
    downloadUrl: '',
    payload: {},
  },
  documentType: ContractDocumentType.financeAgreement,
  mode: SignPageState.Loading,
  showSaveLaterModal: false,
  saveLaterModalMode: 'automatic',
  saveLaterRequest: {
    isLoading: false,
  },
};
