import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../context/appContext';
import ContractComplete from './ContractComplete';
import useContractComplete from './useContractComplete';
import DownloadErrorModal from './DownloadErrorModal';
import { appendPageTitle } from '../../utils/AppUtil';

export default function Complete(): React.JSX.Element {
  const {
    state,
    onDownload,
    onContinue,
    onCloseDownloadErrorModal,
  } = useContractComplete();
  const appContext = useContext(AppContext);

  useEffect(() => {
    appendPageTitle('Download');
  }, []);

  return (
    <>
      <ContractComplete
        showDownloadErrorModal={state.showDownloadErrorModal}
        isInIframe={appContext.isInIframe}
        contracts={appContext.signedContracts}
        onDownloadClick={onDownload}
        onContinueClick={onContinue}
      />
      {state.showDownloadErrorModal && (
        <DownloadErrorModal onCloseModalClick={onCloseDownloadErrorModal} />
      )}
    </>
  );
}
