import { datadogRum } from '@datadog/browser-rum';
import { Analytics } from './Analytics';

export function createDatadogAnalytics(): Analytics {
  if (!window?.appConfig?.datadogRumApplicationId
      || !window?.appConfig?.datadogRumClientToken) {
    console.log('Datadog is not configured');
    return null;
  }

  datadogRum.init({
    applicationId: window.appConfig.datadogRumApplicationId,
    clientToken: window.appConfig.datadogRumClientToken,
    site: 'datadoghq.eu',
    service: 'liberis-contracts-ui',
    env: window.appConfig.env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackingConsent: 'not-granted',
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  console.log('Datadog is configured');

  return Object.freeze({
    setDataSource: () => { },
  
    trackNewOrReturningUser: () => { },

    identifyUser: (applicationId: string, signerId: string) => {
      datadogRum.setUser({
        id: `${applicationId}_${signerId}`,
      });
      datadogRum.setUserProperty('applicationId', applicationId);
      datadogRum.setUserProperty('signerId', signerId);
    },

    enableTracking: () => {
      datadogRum.setTrackingConsent('granted');
      console.log('Datadog started');
    },

    trackContractDisplayed: () => {
      datadogRum.addAction('contract-displayed');
    },

    trackCheckboxClick: () => {},

    trackSignClick: () => {},

    trackComplete: () => {},

    trackDownloadRequested: () => {},

    trackDownloadStarted: () => {},

    trackDownloadFailed: () => {},

    trackApi: () => {},

    setUserProperty: () => {},
  });
}
