export const ActivityEventType = {
  agreed: 'agreed',
  disagreed: 'disagreed',
  displayed: 'displayed',
  sent: 'sent',
  updated: 'updated',
  visited: 'visited',
};

export type ActivityEvent = typeof ActivityEventType[keyof typeof ActivityEventType];

export interface ContractActivity {
  sid: string;
  sig: string;
  gid: string;
  gkey: string;
  vid: string[];
  cid: string[];
  cus: any;
  rnd: any;
  et: ActivityEvent;
}