/* eslint-disable react/require-default-props */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useContext } from 'react';
import { Text, Heading, Modal, Button } from 'liberis-component-library';
import styles from './ContractComplete.scss';
import useLocale from '../../hooks/useLocale';
import { AppContext } from '../../context/appContext';

interface Props {
  onCloseModalClick: () => void;
}
const DownloadErrorModal: FC<Props> = ({ onCloseModalClick }) => {
  const appContext = useContext(AppContext);
  const { getLocale } = useLocale();
  
  return (
    <Modal closeModalClickHandler={onCloseModalClick} pageContentSelector='#root'>
      <div className={styles.modalBody}>
        <Heading heading='h2'>{getLocale('DownloadFailureTitle')}</Heading>
        <Text>{getLocale('DownloadFailureMessage', appContext)}</Text>
        <Button data-modalclose>{getLocale('BackToPage')}</Button>
      </div>
    </Modal>
  );
};

export default DownloadErrorModal;