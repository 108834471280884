import { useState, useRef } from 'react';
import { ApiService } from '../../services';
import ContractLink from '../../models/contractLink';
import useExceptionFlow from '../../hooks/useExceptionFlow';

const usePageExpiry = (): {
  isLoading: boolean;
  isSent: boolean;
  onSend: () => void;
} => {
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ApiRef = useRef<ApiService>(new ApiService());
  const { exceptionFlow } = useExceptionFlow();

  /**
   * Purpose: When the user clicks on link from email that is expired then they land on the session expire page
   * if the email is empty then the email will send to the actual user, testing can be done by entering an
   * email address
   * e.g  email: 'example@liberis.co.uk',
   */

  const SendLink = async () => {
    const linkRequest: ContractLink = {
      email: '',
      locale: '',
    };

    try {
      setIsLoading(true);
      await ApiRef.current.postContractLink(linkRequest);
      setIsLoading(false);
      setIsSent(true);
    } catch (e) {
      exceptionFlow(e, 'ErrorInSendLink');
    }
  };

  return {
    isLoading,
    isSent,
    onSend: SendLink,
  };
};

export default usePageExpiry;
