import { datadogRum } from '@datadog/browser-rum';
import { Analytics } from './Analytics';

export function createDatadogAnalytics(): Analytics {
  if (!window?.appConfig?.datadogRumApplicationId
      || !window?.appConfig?.datadogRumClientToken) {
    console.log('Datadog is not configured');
    return null;
  }

  datadogRum.init({
    applicationId: window.appConfig.datadogRumApplicationId,
    clientToken: window.appConfig.datadogRumClientToken,
    site: 'datadoghq.eu',
    service: 'liberis-contracts-ui',
    env: window.appConfig.env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackingConsent: 'not-granted',
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  console.log('Datadog is configured');

  return Object.freeze({
    setDataSource: () => { },
  
    trackNewOrReturningUser: () => { },

    identifyUser: (applicationId: string, signerId: string) => {
      datadogRum.addAction('identify-user', {
        applicationId,
        signerId,
      });
      datadogRum.setUser({
        id: `${applicationId}_${signerId}`,
      });
      datadogRum.setUserProperty('applicationId', applicationId);
      datadogRum.setUserProperty('signerId', signerId);
    },

    enableTracking: () => {
      datadogRum.setTrackingConsent('granted');
      console.log('Datadog started');
    },

    trackContractDisplayed: () => {
      datadogRum.addAction('contract-displayed');
    },

    trackCheckboxClick: () => {},

    trackSignClick: () => {},

    trackComplete: () => {},

    trackDownloadRequested: () => {},

    trackDownloadStarted: () => {},

    trackDownloadFailed: () => {},

    trackApi: () => {},

    setUserProperty: () => {},

    trackError: (error: Error | string, context?: Record<string, any>) => {
      try {
        const errorMessage = error instanceof Error ? error.message : error;
        const errorName = error instanceof Error ? error.name : 'Error';
        const errorStack = error instanceof Error ? error.stack : undefined;

        // Extract additional metadata for network errors
        const errorMetadata: Record<string, any> = {
          ...context,
          errorName,
          errorMessage,
          errorStack,
        };

        // Add axios error specific metadata
        if (error instanceof Error && 'isAxiosError' in error) {
          const axiosError = error as any;
          Object.assign(errorMetadata, {
            isAxiosError: true,
            request: {
              url: axiosError?.config?.url,
              method: axiosError?.config?.method,
              timeout: axiosError?.config?.timeout,
              headers: axiosError?.config?.headers,
              data: axiosError?.config?.data,
              params: axiosError?.config?.params,
              baseURL: axiosError?.config?.baseURL,
            },
            response: axiosError?.response ? {
              status: axiosError.response.status,
              statusText: axiosError.response.statusText,
              headers: axiosError.response.headers,
              data: axiosError.response.data,
            } : undefined
          });
          
          // Network error specific info (when there's no response)
          if (!axiosError.response) {
            Object.assign(errorMetadata, {
              isNetworkError: true,
              networkError: {
                code: axiosError?.code,
                syscall: axiosError?.syscall,
                address: axiosError?.address,
                port: axiosError?.port,
                errno: axiosError?.errno,
              }
            });
          }
        }

        datadogRum.addError(error, errorMetadata);
      } catch (metadataError) {
        // If metadata extraction fails, still log the original error but with basic context
        console.warn('Failed to extract error metadata:', metadataError);
        datadogRum.addError(error, {
          ...context,
          metadataExtractionFailed: true,
          metadataError: metadataError instanceof Error ? metadataError.message : String(metadataError)
        });
      }
    },

    trackEvent: (eventName: string, properties?: Record<string, any>) => {
      datadogRum.addAction(eventName, properties);
    },
  });
}
