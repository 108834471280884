import React, { useEffect } from 'react';
import PageExpiry from './PageExpiry';
import usePageExpiry from './usePageExpiry';
import { appendPageTitle } from '../../utils/AppUtil';

export default function PageExpiryWithPageTitle(): React.JSX.Element {
  const { isLoading, isSent, onSend } = usePageExpiry();

  useEffect(() => {
    appendPageTitle('Link Expired');
  }, []);

  return (
    <PageExpiry isLoading={isLoading} isSent={isSent} onSendClick={onSend} />
  );
}
