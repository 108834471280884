export type AnalyticsEvent = {
  eventName: string;
  properties?: Record<string, unknown>;
  timestamp: number;
};

export enum AnalyticsEventName {
  ContractDisplayed = 'Contract Displayed',
  SignClicked = 'Sign Clicked',
  SignatureComplete = 'Signature Complete',
  CheckboxClicked = 'Checkbox Clicked',
  DownloadRequested = 'Download Requested',
  DownloadStarted = 'Download Started',
  DownloadFailed = 'Download Failed',
  UserIdentified = 'User Identified',
  Complete = 'Complete',
  ApiCall = 'Api Call',
}
