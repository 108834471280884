import { CardReader } from 'liberis-component-library';
import React, { FC } from 'react';
import { ContractBody } from '../ContractSign.types';
import style from './LegacyContractRenderer.scss';

export interface LegacyContractRendererProps {
  contract: ContractBody;
  onValidHandler: (complete: boolean) => void;
}

const LegacyContractRenderer: FC<LegacyContractRendererProps> = ({
  contract,
  onValidHandler,
}): JSX.Element => {

  return <CardReader
    className={style.htmlContent}
    onScrollComplete={onValidHandler}
    content={contract.content}
  />;
};

export default LegacyContractRenderer;
