const StorageKeys = {
  IdToken: 'idToken',
  TestToken: 'testToken',
} as const;

type StorageTypeKeys = typeof StorageKeys[keyof typeof StorageKeys];

export { StorageKeys, StorageTypeKeys };

export interface JWTPayload {
  PartnerCode: string;
  Branding: string;
  ApplicationId: string;
  IsPrimary: string;
  exp: number;
}

export const Routes = {
  root: '/',
  complete: '/complete',
  expire: '/expire',
  error: '/error',
} as const;
