import React from 'react';
import { ComponentWithChildren } from '../models/ComponentWithChildren';
import { Liberis, IframeLayout } from '.';
import { isInIframe } from '../utils/AppUtil';

const LayoutFactory = ({ children }: ComponentWithChildren) => {
  const isIFrame = isInIframe();
  if (isIFrame) {
    return <IframeLayout>{children}</IframeLayout>;
  }
  return <Liberis>{children}</Liberis>;
};

export default LayoutFactory;
