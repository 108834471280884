/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { ComponentWithChildren } from '../../models/ComponentWithChildren';
import styles from './messageLayout.scss';

const LiberisMessageLayout = ({ children }: ComponentWithChildren) => (
  <div
    data-testid='liberis-message-layout'
    className={styles.msgLiberisContainer}
  >
    <div className={styles.content}>
      <main className={styles.message}>{children}</main>
    </div>
  </div>
);

export default LiberisMessageLayout;
